import update from 'immutability-helper'
import { FETCH_BLOGS_REQUEST } from 'v2/spas/blogs/redux/action-types.tsx'
import { FETCH_BLOGS_SUCCESS } from 'v2/spas/blogs/redux/action-types.tsx'
import { FETCH_BLOGS_FAILURE } from 'v2/spas/blogs/redux/action-types.tsx'
import { UPDATE_BLOG_REQUEST } from 'v2/spas/blogs/redux/action-types.tsx'
import { UPDATE_BLOG_SUCCESS } from 'v2/spas/blogs/redux/action-types.tsx'
import { UPDATE_BLOG_FAILURE } from 'v2/spas/blogs/redux/action-types.tsx'
import { DELETE_BLOG_REQUEST } from 'v2/spas/blogs/redux/action-types.tsx'
import { DELETE_BLOG_SUCCESS } from 'v2/spas/blogs/redux/action-types.tsx'
import { DELETE_BLOG_FAILURE } from 'v2/spas/blogs/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  blogs: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOGS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_BLOGS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_BLOGS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        blogs: {
          $set: action.payload.blogs
        },
      })
    }

    case UPDATE_BLOG_REQUEST: {
      return state
    }

    case UPDATE_BLOG_FAILURE: {
      return state
    }

    case UPDATE_BLOG_SUCCESS: {
      let index = state.blogs.findIndex(
        x => x.id === action.payload.blog.id,
      )

      return update(state, {
        blogs: {
          [index]: { $set: action.payload.blog },
        },
      })
    }

    case DELETE_BLOG_REQUEST: {
      return state
    }
    
    case DELETE_BLOG_FAILURE: {
      return state
    }
    
    case DELETE_BLOG_SUCCESS: {
      let index = state.blogs.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        blogs: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
