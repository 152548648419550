import update from 'immutability-helper'
import { FETCH_CATEGORY_REQUEST } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { FETCH_CATEGORY_SUCCESS } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { FETCH_CATEGORY_FAILURE } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { UPDATE_CATEGORY_REQUEST } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { UPDATE_CATEGORY_SUCCESS } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { UPDATE_CATEGORY_FAILURE } from 'v2/spas/blog/categories/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  category: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_CATEGORY_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_CATEGORY_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        category: {
          $set: action.payload.category,
        }
      })
    }

    case UPDATE_CATEGORY_REQUEST: {
      return update(state, {
        updateCategoryStatus: { $set: 'requested' },
      })
    }

    case UPDATE_CATEGORY_FAILURE: {
      return update(state, {
        updateCategoryStatus: {
          $set: 'error',
        },
        updateCategoryError: { $set: action.payload.error },
      })
    }

    case UPDATE_CATEGORY_SUCCESS: {
      return update(state, {
        updateCategoryStatus: { $set: 'success' },
        category: {
          $set: action.payload.category,
        }
      })
    }

    default:
      return state
  }
}