import { combineReducers } from 'redux'
import authentication from './authentication'
import profile from './profile'
import accounts from 'v2/spas/accounts/redux/reducers/index.tsx'
import events from 'v2/spas/events/redux/reducers/index.tsx'
import accountUsers from 'v2/spas/account-users/redux/reducers/index.tsx'
import accountUser from 'v2/spas/account-user/account-user/redux/reducers/view.tsx'
import accountUserEvents from 'v2/spas/account-user/events/redux/reducers/index.tsx'
import accountUserActivityFeedItems from 'v2/spas/account-user/activity-feed-items/redux/reducers/index.tsx'
import blogs from 'v2/spas/blogs/redux/reducers/index.tsx'
import blog from 'v2/spas/blogs/redux/reducers/view.tsx'
import blogCategories from 'v2/spas/blog/categories/redux/reducers/index.tsx'
import blogCategory from 'v2/spas/blog/categories/redux/reducers/view.tsx'
import categoryArticles from 'v2/spas/category/articles/redux/reducers/index.tsx'
import categoryArticle from 'v2/spas/category/articles/redux/reducers/view.tsx'
import knowledgeBases from 'v2/spas/knowledge-bases/redux/reducers/index.tsx'
import knowledgeBase from 'v2/spas/knowledge-bases/redux/reducers/view.tsx'
import knowledgeBaseCategories from 'v2/spas/knowledge-base/categories/redux/reducers/index.tsx'
import knowledgeBaseCategory from 'v2/spas/knowledge-base/categories/redux/reducers/view.tsx'
import categorySections from 'v2/spas/category/sections/redux/reducers/index.tsx'
import categorySection from 'v2/spas/category/sections/redux/reducers/view.tsx'
import sectionArticles from 'v2/spas/section/articles/redux/reducers/index.tsx'
import sectionArticle from 'v2/spas/section/articles/redux/reducers/view.tsx'

const rootReducer = combineReducers({
  authentication,
  profile,
  accounts,
  events,
  accountUsers,
  accountUser,
  accountUserEvents,
  accountUserActivityFeedItems,
  blogs,
  blog,
  blogCategories,
  blogCategory,
  categoryArticles,
  categoryArticle,
  knowledgeBases,
  knowledgeBase,
  knowledgeBaseCategories,
  knowledgeBaseCategory,
  categorySections,
  categorySection,
  sectionArticles,
  sectionArticle,
})

export default rootReducer