import update from 'immutability-helper'
import { FETCH_SECTION_REQUEST } from 'v2/spas/category/sections/redux/action-types.tsx'
import { FETCH_SECTION_SUCCESS } from 'v2/spas/category/sections/redux/action-types.tsx'
import { FETCH_SECTION_FAILURE } from 'v2/spas/category/sections/redux/action-types.tsx'
import { UPDATE_SECTION_REQUEST } from 'v2/spas/category/sections/redux/action-types.tsx'
import { UPDATE_SECTION_SUCCESS } from 'v2/spas/category/sections/redux/action-types.tsx'
import { UPDATE_SECTION_FAILURE } from 'v2/spas/category/sections/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  section: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SECTION_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_SECTION_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_SECTION_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        section: {
          $set: action.payload.section,
        }
      })
    }

    case UPDATE_SECTION_REQUEST: {
      return update(state, {
        updateSectionStatus: { $set: 'requested' },
      })
    }

    case UPDATE_SECTION_FAILURE: {
      return update(state, {
        updateSectionStatus: {
          $set: 'error',
        },
        updateSectionError: { $set: action.payload.error },
      })
    }

    case UPDATE_SECTION_SUCCESS: {
      return update(state, {
        updateSectionStatus: { $set: 'success' },
        section: {
          $set: action.payload.section,
        }
      })
    }

    default:
      return state
  }
}