export const FETCH_KNOWLEDGE_BASES_REQUEST = 'FETCH_KNOWLEDGE_BASES_REQUEST'
export const FETCH_KNOWLEDGE_BASES_SUCCESS = 'FETCH_KNOWLEDGE_BASES_SUCCESS'
export const FETCH_KNOWLEDGE_BASES_FAILURE = 'FETCH_KNOWLEDGE_BASES_FAILURE'

export const FETCH_KNOWLEDGE_BASE_REQUEST = 'FETCH_KNOWLEDGE_BASE_REQUEST'
export const FETCH_KNOWLEDGE_BASE_SUCCESS = 'FETCH_KNOWLEDGE_BASE_SUCCESS'
export const FETCH_KNOWLEDGE_BASE_FAILURE = 'FETCH_KNOWLEDGE_BASE_FAILURE'

export const CREATE_KNOWLEDGE_BASE_REQUEST = 'CREATE_KNOWLEDGE_BASE_REQUEST'
export const CREATE_KNOWLEDGE_BASE_SUCCESS = 'CREATE_KNOWLEDGE_BASE_SUCCESS'
export const CREATE_KNOWLEDGE_BASE_FAILURE = 'CREATE_KNOWLEDGE_BASE_FAILURE'

export const UPDATE_KNOWLEDGE_BASE_REQUEST = 'UPDATE_KNOWLEDGE_BASE_REQUEST'
export const UPDATE_KNOWLEDGE_BASE_SUCCESS = 'UPDATE_KNOWLEDGE_BASE_SUCCESS'
export const UPDATE_KNOWLEDGE_BASE_FAILURE = 'UPDATE_KNOWLEDGE_BASE_FAILURE'

export const DELETE_KNOWLEDGE_BASE_REQUEST = 'DELETE_KNOWLEDGE_BASE_REQUEST'
export const DELETE_KNOWLEDGE_BASE_SUCCESS = 'DELETE_KNOWLEDGE_BASE_SUCCESS'
export const DELETE_KNOWLEDGE_BASE_FAILURE = 'DELETE_KNOWLEDGE_BASE_FAILURE'