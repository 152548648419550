import update from 'immutability-helper'
import { FETCH_CATEGORY_ARTICLES_REQUEST } from 'v2/spas/category/articles/redux/action-types.tsx'
import { FETCH_CATEGORY_ARTICLES_SUCCESS } from 'v2/spas/category/articles/redux/action-types.tsx'
import { FETCH_CATEGORY_ARTICLES_FAILURE } from 'v2/spas/category/articles/redux/action-types.tsx'
import { ARCHIVE_ARTICLE_REQUEST } from 'v2/spas/category/articles/redux/action-types.tsx'
import { ARCHIVE_ARTICLE_SUCCESS } from 'v2/spas/category/articles/redux/action-types.tsx'
import { ARCHIVE_ARTICLE_FAILURE } from 'v2/spas/category/articles/redux/action-types.tsx'
import { UPDATE_ARTICLE_REQUEST } from 'v2/spas/category/articles/redux/action-types.tsx'
import { UPDATE_ARTICLE_SUCCESS } from 'v2/spas/category/articles/redux/action-types.tsx'
import { UPDATE_ARTICLE_FAILURE } from 'v2/spas/category/articles/redux/action-types.tsx'
import { DELETE_ARTICLE_REQUEST } from 'v2/spas/category/articles/redux/action-types.tsx'
import { DELETE_ARTICLE_SUCCESS } from 'v2/spas/category/articles/redux/action-types.tsx'
import { DELETE_ARTICLE_FAILURE } from 'v2/spas/category/articles/redux/action-types.tsx'
import { ADD_ARTICLE_DOCUMENT_REQUEST } from 'v2/spas/category/articles/redux/action-types.tsx'
import { ADD_ARTICLE_DOCUMENT_SUCCESS } from 'v2/spas/category/articles/redux/action-types.tsx'
import { ADD_ARTICLE_DOCUMENT_FAILURE } from 'v2/spas/category/articles/redux/action-types.tsx'
import { UPLOAD_ARTICLE_DOCUMENT_FILE_REQUEST } from 'v2/spas/category/articles/redux/action-types.tsx'
import { UPLOAD_ARTICLE_DOCUMENT_FILE_SUCCESS } from 'v2/spas/category/articles/redux/action-types.tsx'
import { UPLOAD_ARTICLE_DOCUMENT_FILE_FAILURE } from 'v2/spas/category/articles/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  category: {},
  articles: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY_ARTICLES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_CATEGORY_ARTICLES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_CATEGORY_ARTICLES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        category: {
          $set: action.payload.category,
        },
        articles: {
          $set: action.payload.category.articles
        },
      })
    }

    case UPDATE_ARTICLE_REQUEST: {
      return state
    }

    case UPDATE_ARTICLE_FAILURE: {
      return state
    }

    case UPDATE_ARTICLE_SUCCESS: {
      let index = state.articles.findIndex(
        x => x.id === action.payload.article.id,
      )

      return update(state, {
        articles: {
          [index]: { $set: action.payload.article },
        },
      })
    }

    case ARCHIVE_ARTICLE_REQUEST: {
      return state
    }

    case ARCHIVE_ARTICLE_FAILURE: {
      return state
    }

    case ARCHIVE_ARTICLE_SUCCESS: {
      let index = state.articles.findIndex(x => x.id === action.payload.article.id)

      return update(state, {
        articles: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_ARTICLE_REQUEST: {
      return state
    }
    
    case DELETE_ARTICLE_FAILURE: {
      return state
    }
    
    case DELETE_ARTICLE_SUCCESS: {
      let index = state.articles.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        articles: {
          $splice: [[index, 1]],
        },
      })
    }

    case ADD_ARTICLE_DOCUMENT_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case ADD_ARTICLE_DOCUMENT_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case ADD_ARTICLE_DOCUMENT_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
      })
    }

    case UPLOAD_ARTICLE_DOCUMENT_FILE_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case UPLOAD_ARTICLE_DOCUMENT_FILE_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case UPLOAD_ARTICLE_DOCUMENT_FILE_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
      })
    }

    default:
      return state
  }
}
