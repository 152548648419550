export const FETCH_CATEGORY_SECTIONS_REQUEST = 'FETCH_CATEGORY_SECTIONS_REQUEST'
export const FETCH_CATEGORY_SECTIONS_SUCCESS = 'FETCH_CATEGORY_SECTIONS_SUCCESS'
export const FETCH_CATEGORY_SECTIONS_FAILURE = 'FETCH_CATEGORY_SECTIONS_FAILURE'

export const FETCH_SECTION_REQUEST = 'FETCH_SECTION_REQUEST'
export const FETCH_SECTION_SUCCESS = 'FETCH_SECTION_SUCCESS'
export const FETCH_SECTION_FAILURE = 'FETCH_SECTION_FAILURE'

export const CREATE_SECTION_REQUEST = 'CREATE_SECTION_REQUEST'
export const CREATE_SECTION_SUCCESS = 'CREATE_SECTION_SUCCESS'
export const CREATE_SECTION_FAILURE = 'CREATE_SECTION_FAILURE'

export const UPDATE_SECTION_REQUEST = 'UPDATE_SECTION_REQUEST'
export const UPDATE_SECTION_SUCCESS = 'UPDATE_SECTION_SUCCESS'
export const UPDATE_SECTION_FAILURE = 'UPDATE_SECTION_FAILURE'

export const ARCHIVE_SECTION_REQUEST = 'ARCHIVE_SECTION_REQUEST'
export const ARCHIVE_SECTION_SUCCESS = 'ARCHIVE_SECTION_SUCCESS'
export const ARCHIVE_SECTION_FAILURE = 'ARCHIVE_SECTION_FAILURE'

export const UNARCHIVE_SECTION_REQUEST = 'UNARCHIVE_SECTION_REQUEST'
export const UNARCHIVE_SECTION_SUCCESS = 'UNARCHIVE_SECTION_SUCCESS'
export const UNARCHIVE_SECTION_FAILURE = 'UNARCHIVE_SECTION_FAILURE'

export const DELETE_SECTION_REQUEST = 'DELETE_SECTION_REQUEST'
export const DELETE_SECTION_SUCCESS = 'DELETE_SECTION_SUCCESS'
export const DELETE_SECTION_FAILURE = 'DELETE_SECTION_FAILURE'