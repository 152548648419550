import update from 'immutability-helper'
import { FETCH_KNOWLEDGE_BASES_REQUEST } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { FETCH_KNOWLEDGE_BASES_SUCCESS } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { FETCH_KNOWLEDGE_BASES_FAILURE } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { UPDATE_KNOWLEDGE_BASE_REQUEST } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { UPDATE_KNOWLEDGE_BASE_SUCCESS } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { UPDATE_KNOWLEDGE_BASE_FAILURE } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { DELETE_KNOWLEDGE_BASE_REQUEST } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { DELETE_KNOWLEDGE_BASE_SUCCESS } from 'v2/spas/knowledge-bases/redux/action-types.tsx'
import { DELETE_KNOWLEDGE_BASE_FAILURE } from 'v2/spas/knowledge-bases/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  knowledgeBases: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_KNOWLEDGE_BASES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_KNOWLEDGE_BASES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_KNOWLEDGE_BASES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        knowledgeBases: {
          $set: action.payload.knowledgeBases
        },
      })
    }

    case UPDATE_KNOWLEDGE_BASE_REQUEST: {
      return state
    }

    case UPDATE_KNOWLEDGE_BASE_FAILURE: {
      return state
    }

    case UPDATE_KNOWLEDGE_BASE_SUCCESS: {
      let index = state.knowledgeBases.findIndex(
        x => x.id === action.payload.knowledgeBase.id,
      )

      return update(state, {
        knowledgeBases: {
          [index]: { $set: action.payload.knowledgeBase },
        },
      })
    }

    case DELETE_KNOWLEDGE_BASE_REQUEST: {
      return state
    }
    
    case DELETE_KNOWLEDGE_BASE_FAILURE: {
      return state
    }
    
    case DELETE_KNOWLEDGE_BASE_SUCCESS: {
      let index = state.knowledgeBases.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        knowledgeBases: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
