import update from 'immutability-helper'
import { FETCH_ACCOUNT_USERS_REQUEST } from 'v2/spas/account-users/redux/action-types.tsx'
import { FETCH_ACCOUNT_USERS_SUCCESS } from 'v2/spas/account-users/redux/action-types.tsx'
import { FETCH_ACCOUNT_USERS_FAILURE } from 'v2/spas/account-users/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  accountUsers: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_USERS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_ACCOUNT_USERS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_ACCOUNT_USERS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        accountUsers: {
          $set: action.payload.accountUsers,
        },
      })
    }

    default:
      return state
  }
}
