import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useEffect, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { AdminTheme } from 'themes'
import { GlobalStyles } from 'themes'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { HelmetProvider } from "react-helmet-async";
import { Spinner } from 'functional-components'
import { loadStacked } from 'redux/actions/profile/loadStacked'
import { logout } from 'redux/actions/authentication/logout'
import { session } from 'redux/actions/authentication/session'
import { useInterval } from 'functions'
import { MortgageBrokerRootPath } from 'functional-components'
import { FetchResource } from 'functional-components'
import { ModalButton } from 'buttons'
import { PageNotFound } from 'pages';
import { ScrollToTop } from 'functional-components'
import { Snackbar } from 'functional-components';
import { ZendeskWidget, PrivateRoute } from 'functional-components';
import { WarningModal } from 'modals'

const Login = lazy(() => import('spas/Login/Login.js'));
const SignInWithMagicLink = lazy(() => import('spas/SignInWithMagicLink/SignInWithMagicLink.tsx'));
const Accounts = lazy(() => import('v2/spas/accounts/pages/index.tsx'));
const AccountUsers = lazy(() => import('v2/spas/account-users/pages/index.tsx'));
const AccountUser = lazy(() => import('v2/spas/account-user/account-user/pages/view.tsx'));
const Events = lazy(() => import('v2/spas/events/pages/index.tsx'));
const AccountUserEvents = lazy(() => import('v2/spas/account-user/events/pages/index.tsx'));
const AccountUserActivityFeedItems = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/index.tsx'));
const AccountUserActivityFeedItemsArchive = lazy(() => import('v2/spas/account-user/activity-feed-items/pages/archive.tsx'));
const Blogs = lazy(() => import('v2/spas/blogs/pages/index.tsx'));
const Blog = lazy(() => import('v2/spas/blogs/pages/view.tsx'));
const BlogCategories = lazy(() => import('v2/spas/blog/categories/pages/index.tsx'));
const BlogCategory = lazy(() => import('v2/spas/blog/categories/pages/view.tsx'));
const BlogCategoriesArchive = lazy(() => import('v2/spas/blog/categories/pages/archive.tsx'));
const CategoryArticles = lazy(() => import('v2/spas/category/articles/pages/index.tsx'));
const CategoryArticle = lazy(() => import('v2/spas/category/articles/pages/view.tsx'));
const CategoryArticlesArchive = lazy(() => import('v2/spas/category/articles/pages/archive.tsx'));
const KnowledgeBases = lazy(() => import('v2/spas/knowledge-bases/pages/index.tsx'));
const KnowledgeBase = lazy(() => import('v2/spas/knowledge-bases/pages/view.tsx'));
const KnowledgeBaseCategories = lazy(() => import('v2/spas/knowledge-base/categories/pages/index.tsx'));
const KnowledgeBaseCategory = lazy(() => import('v2/spas/knowledge-base/categories/pages/view.tsx'));
const KnowledgeBaseCategoriesArchive = lazy(() => import('v2/spas/knowledge-base/categories/pages/archive.tsx'));
const CategorySections = lazy(() => import('v2/spas/category/sections/pages/index.tsx'));
const CategorySection = lazy(() => import('v2/spas/category/sections/pages/view.tsx'));
const CategorySectionsArchive = lazy(() => import('v2/spas/category/sections/pages/archive.tsx'));
const SectionArticles = lazy(() => import('v2/spas/section/articles/pages/index.tsx'));
const SectionArticle = lazy(() => import('v2/spas/section/articles/pages/view.tsx'));
const SectionArticlesArchive = lazy(() => import('v2/spas/section/articles/pages/archive.tsx'));

function initialiseSentry() {
  if (window.location.href.indexOf("stacked.group") > -1) {
    Sentry.init({
      dsn: "https://d422f667e28646289be0cd3d3c5ac859@o4504261359894528.ingest.sentry.io/4504261362909184",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
}

initialiseSentry()

const App = ({
  currentUser,
  currentAccount,
  currentEmailIdentity,
  policy,
  loadStackedStatus,
  loadStackedError,
  sessionStatus,
  sessionError,
  sessionExpiring,
  loadStacked,
  session,
  logout,
}) => {
  const logOut = () => {
    logout(handleLogOut);
  };

  const handleLogOut = response => {
    if (response.status === 'success') {
      window.location.href = '/login';
    } else if (response.status === 'error') {
      window.location.reload();
    }
  };

  useInterval(async () => {
    session();
  }, 600000); // Check every 10 minutes
  
  useEffect(() => {
    loadStacked();
  }, []);

  return (
    <HelmetProvider>
      <AdminTheme>
        <Snackbar>
          <GlobalStyles />
          <Router>
            <Suspense fallback={<Spinner />}>
              <ZendeskWidget />
              <ScrollToTop />
              <FetchResource status={loadStackedStatus} error={loadStackedError}>
                <WarningModal
                  show={sessionExpiring}
                  heading="Your session is about to expire!">
                  <ModalButton
                    text="Please login again"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <WarningModal
                  show={currentUser.signUpStatus === 'confirm_email'}
                  heading="You have not confirmed your email address yet! Please check your emails and click the link to confirm your email address!">
                  <ModalButton
                    text="Continue"
                    backgroundColor={props => props.theme.colors.offWhite}
                    color={props => props.theme.colors.black}
                    handleClick={logOut}
                  />
                </WarningModal>
                <Switch>
                  <Route exact path="/">
                    {MortgageBrokerRootPath(currentUser, currentEmailIdentity, currentAccount)}
                  </Route>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/sign-in-with-magic-link/:token">
                    <SignInWithMagicLink />
                  </Route>
                  <Route
                    exact
                    path="/accounts"
                  >
                    <Accounts />
                  </Route>
                  <Route
                    exact
                    path="/account-users/:accountUserId"
                  >
                    <AccountUser />
                  </Route>
                  <Route
                    exact
                    path="/account-users"
                  >
                    <AccountUsers />
                  </Route>
                  <Route
                    exact
                    path="/events"
                  >
                    <Events />
                  </Route>
                  <Route
                    exact
                    path="/account-users/:accountUserId/events"
                  >
                    <AccountUserEvents />
                  </Route>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/activity-feed-items"
                    authorised={true}>
                    <AccountUserActivityFeedItems />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/account-users/:accountUserId/archived-activity-feed-items"
                    authorised={true}>
                    <AccountUserActivityFeedItemsArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/blogs"
                    authorised={true}>
                    <Blogs />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/blogs/:blogId"
                    authorised={true}>
                    <Blog />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/blogs/:blogId/categories"
                    authorised={true}>
                    <BlogCategories />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/blogs/:blogId/categories/:categoryId"
                    authorised={true}>
                    <BlogCategory />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/blogs/:blogId/archived-categories"
                    authorised={true}>
                    <BlogCategoriesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/articles"
                    authorised={true}>
                    <CategoryArticles />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/articles/:articleId"
                    authorised={true}>
                    <CategoryArticle />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/archived-articles"
                    authorised={true}>
                    <CategoryArticlesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases"
                    authorised={true}>
                    <KnowledgeBases />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases/:knowledgeBaseId"
                    authorised={true}>
                    <KnowledgeBase />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases/:knowledgeBaseId/categories"
                    authorised={true}>
                    <KnowledgeBaseCategories />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases/:knowledgeBaseId/categories/:categoryId"
                    authorised={true}>
                    <KnowledgeBaseCategory />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/knowledge-bases/:knowledgeBaseId/archived-categories"
                    authorised={true}>
                    <KnowledgeBaseCategoriesArchive />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/sections"
                    authorised={true}>
                    <CategorySections />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/sections/:sectionId"
                    authorised={true}>
                    <CategorySection />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/categories/:categoryId/archived-sections"
                    authorised={true}>
                    <CategorySectionsArchive />
                  </PrivateRoute>

                  <PrivateRoute
                    exact
                    path="/sections/:sectionId/articles"
                    authorised={true}>
                    <SectionArticles />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/sections/:sectionId/articles/:articleId"
                    authorised={true}>
                    <SectionArticle />
                  </PrivateRoute>
                  <PrivateRoute
                    exact
                    path="/sections/:sectionId/archived-articles"
                    authorised={true}>
                    <SectionArticlesArchive />
                  </PrivateRoute>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </FetchResource>
            </Suspense>
          </Router>
        </Snackbar>
      </AdminTheme>
    </HelmetProvider>
  );
};

const mapStateToProps = state => ({
  loadStackedStatus: state.profile.loadStackedStatus,
  loadStackedError: state.profile.loadStackedError,
  currentUser: state.profile.currentUser,
  currentEmailIdentity: state.profile.currentEmailIdentity,
  currentAccount: state.profile.currentAccount,
  policy: state.profile.policy,
  sessionStatus: state.authentication.sessionStatus,
  sessionError: state.authentication.sessionError,
  sessionExpiring: state.authentication.sessionExpiring,
});

const mapDispatchToProps = {
  loadStacked: loadStacked,
  session: session,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);