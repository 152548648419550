import React from 'react'
import styled from 'styled-components'
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom'
import { useCurrentAccount } from 'functions'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0px; 
  padding: ${props => props.theme.space.m};

  svg {
    color: black;
    cursor: pointer;
  }
`

function SupportWidget() {
  const currentAccount = useCurrentAccount()
  let knowledgeBase = currentAccount.knowledgeBase || {}

  if (currentAccount.id) {
    return (
      <Wrapper>
        <Link to={`/knowledge-bases/${knowledgeBase.id}/categories`} target='_blank' rel='noopener noreferer'>
          <QuestionCircleFill size={60} />
        </Link>
      </Wrapper>
    )
  } else {
    return null
  }
}


export default SupportWidget

