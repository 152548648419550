export const FETCH_CATEGORY_ARTICLES_REQUEST = 'FETCH_CATEGORY_ARTICLES_REQUEST'
export const FETCH_CATEGORY_ARTICLES_SUCCESS = 'FETCH_CATEGORY_ARTICLES_SUCCESS'
export const FETCH_CATEGORY_ARTICLES_FAILURE = 'FETCH_CATEGORY_ARTICLES_FAILURE'

export const FETCH_ARTICLE_REQUEST = 'FETCH_ARTICLE_REQUEST'
export const FETCH_ARTICLE_SUCCESS = 'FETCH_ARTICLE_SUCCESS'
export const FETCH_ARTICLE_FAILURE = 'FETCH_ARTICLE_FAILURE'

export const CREATE_ARTICLE_REQUEST = 'CREATE_ARTICLE_REQUEST'
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS'
export const CREATE_ARTICLE_FAILURE = 'CREATE_ARTICLE_FAILURE'

export const UPDATE_ARTICLE_REQUEST = 'UPDATE_ARTICLE_REQUEST'
export const UPDATE_ARTICLE_SUCCESS = 'UPDATE_ARTICLE_SUCCESS'
export const UPDATE_ARTICLE_FAILURE = 'UPDATE_ARTICLE_FAILURE'

export const ARCHIVE_ARTICLE_REQUEST = 'ARCHIVE_ARTICLE_REQUEST'
export const ARCHIVE_ARTICLE_SUCCESS = 'ARCHIVE_ARTICLE_SUCCESS'
export const ARCHIVE_ARTICLE_FAILURE = 'ARCHIVE_ARTICLE_FAILURE'

export const UNARCHIVE_ARTICLE_REQUEST = 'UNARCHIVE_ARTICLE_REQUEST'
export const UNARCHIVE_ARTICLE_SUCCESS = 'UNARCHIVE_ARTICLE_SUCCESS'
export const UNARCHIVE_ARTICLE_FAILURE = 'UNARCHIVE_ARTICLE_FAILURE'

export const DELETE_ARTICLE_REQUEST = 'DELETE_ARTICLE_REQUEST'
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS'
export const DELETE_ARTICLE_FAILURE = 'DELETE_ARTICLE_FAILURE'

export const ADD_ARTICLE_DOCUMENT_REQUEST = 'ADD_ARTICLE_DOCUMENT_REQUEST'
export const ADD_ARTICLE_DOCUMENT_SUCCESS = 'ADD_ARTICLE_DOCUMENT_SUCCESS'
export const ADD_ARTICLE_DOCUMENT_FAILURE = 'ADD_ARTICLE_DOCUMENT_FAILURE'

export const UPLOAD_ARTICLE_DOCUMENT_FILE_REQUEST = 'UPLOAD_ARTICLE_DOCUMENT_FILE_REQUEST'
export const UPLOAD_ARTICLE_DOCUMENT_FILE_SUCCESS = 'UPLOAD_ARTICLE_DOCUMENT_FILE_SUCCESS'
export const UPLOAD_ARTICLE_DOCUMENT_FILE_FAILURE = 'UPLOAD_ARTICLE_DOCUMENT_FILE_FAILURE'