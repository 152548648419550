import React from 'react'
import styled from 'styled-components'
import {NavbarLink} from 'navs'
// import Support from 'sharedComponents/UserTopNavbar/Support.js'
// import Academy from 'sharedComponents/UserTopNavbar/Academy.js'
// import Community from 'sharedComponents/UserTopNavbar/Community.js'
// import Search from 'sharedComponents/UserTopNavbar/Search.js'
import ProfileMenu from 'sharedComponents/UserTopNavbar/ProfileMenu.js'
import {NotificationBell} from 'badges'
// import CalendarIcon from 'sharedComponents/CalendarIcon';
import { useCurrentAccount } from 'functions'
import { useCurrentAccountUser } from 'functions'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

function Links(props) {

  const currentAccount = useCurrentAccount()
  const currentAccountUser = useCurrentAccountUser()

  return (
    <Wrapper>
      {/* <Search /> */}
      {/* <Support /> */}
      {/* <Community /> */}
      {/* <Academy /> */}
      {/* <CalendarIcon /> */}
      {/* <NotificationBell /> */}
      <ProfileMenu />
    </Wrapper>
  )
}

export default Links
