import update from 'immutability-helper'
import { FETCH_CATEGORY_SECTIONS_REQUEST } from 'v2/spas/category/sections/redux/action-types.tsx'
import { FETCH_CATEGORY_SECTIONS_SUCCESS } from 'v2/spas/category/sections/redux/action-types.tsx'
import { FETCH_CATEGORY_SECTIONS_FAILURE } from 'v2/spas/category/sections/redux/action-types.tsx'
import { ARCHIVE_SECTION_REQUEST } from 'v2/spas/category/sections/redux/action-types.tsx'
import { ARCHIVE_SECTION_SUCCESS } from 'v2/spas/category/sections/redux/action-types.tsx'
import { ARCHIVE_SECTION_FAILURE } from 'v2/spas/category/sections/redux/action-types.tsx'
import { UPDATE_SECTION_REQUEST } from 'v2/spas/category/sections/redux/action-types.tsx'
import { UPDATE_SECTION_SUCCESS } from 'v2/spas/category/sections/redux/action-types.tsx'
import { UPDATE_SECTION_FAILURE } from 'v2/spas/category/sections/redux/action-types.tsx'
import { DELETE_SECTION_REQUEST } from 'v2/spas/category/sections/redux/action-types.tsx'
import { DELETE_SECTION_SUCCESS } from 'v2/spas/category/sections/redux/action-types.tsx'
import { DELETE_SECTION_FAILURE } from 'v2/spas/category/sections/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  category: {},
  sections: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CATEGORY_SECTIONS_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_CATEGORY_SECTIONS_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_CATEGORY_SECTIONS_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        category: {
          $set: action.payload.category,
        },
        sections: {
          $set: action.payload.category.sections
        },
      })
    }

    case UPDATE_SECTION_REQUEST: {
      return state
    }

    case UPDATE_SECTION_FAILURE: {
      return state
    }

    case UPDATE_SECTION_SUCCESS: {
      let index = state.sections.findIndex(
        x => x.id === action.payload.section.id,
      )

      return update(state, {
        sections: {
          [index]: { $set: action.payload.section },
        },
      })
    }

    case ARCHIVE_SECTION_REQUEST: {
      return state
    }

    case ARCHIVE_SECTION_FAILURE: {
      return state
    }

    case ARCHIVE_SECTION_SUCCESS: {
      let index = state.sections.findIndex(x => x.id === action.payload.section.id)

      return update(state, {
        sections: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_SECTION_REQUEST: {
      return state
    }
    
    case DELETE_SECTION_FAILURE: {
      return state
    }
    
    case DELETE_SECTION_SUCCESS: {
      let index = state.sections.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        sections: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
