export const FETCH_BLOG_CATEGORIES_REQUEST = 'FETCH_BLOG_CATEGORIES_REQUEST'
export const FETCH_BLOG_CATEGORIES_SUCCESS = 'FETCH_BLOG_CATEGORIES_SUCCESS'
export const FETCH_BLOG_CATEGORIES_FAILURE = 'FETCH_BLOG_CATEGORIES_FAILURE'

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST'
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS'
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE'

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE'

export const ARCHIVE_CATEGORY_REQUEST = 'ARCHIVE_CATEGORY_REQUEST'
export const ARCHIVE_CATEGORY_SUCCESS = 'ARCHIVE_CATEGORY_SUCCESS'
export const ARCHIVE_CATEGORY_FAILURE = 'ARCHIVE_CATEGORY_FAILURE'

export const UNARCHIVE_CATEGORY_REQUEST = 'UNARCHIVE_CATEGORY_REQUEST'
export const UNARCHIVE_CATEGORY_SUCCESS = 'UNARCHIVE_CATEGORY_SUCCESS'
export const UNARCHIVE_CATEGORY_FAILURE = 'UNARCHIVE_CATEGORY_FAILURE'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'