import update from 'immutability-helper'
import { FETCH_BLOG_CATEGORIES_REQUEST } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { FETCH_BLOG_CATEGORIES_SUCCESS } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { FETCH_BLOG_CATEGORIES_FAILURE } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { ARCHIVE_CATEGORY_REQUEST } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { ARCHIVE_CATEGORY_SUCCESS } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { ARCHIVE_CATEGORY_FAILURE } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { UPDATE_CATEGORY_REQUEST } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { UPDATE_CATEGORY_SUCCESS } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { UPDATE_CATEGORY_FAILURE } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { DELETE_CATEGORY_REQUEST } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { DELETE_CATEGORY_SUCCESS } from 'v2/spas/blog/categories/redux/action-types.tsx'
import { DELETE_CATEGORY_FAILURE } from 'v2/spas/blog/categories/redux/action-types.tsx'

const initialState = {
  status: 'requested',
  error: '',
  blog: {},
  categories: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BLOG_CATEGORIES_REQUEST: {
      return update(state, {
        status: { $set: 'requested' },
      })
    }

    case FETCH_BLOG_CATEGORIES_FAILURE: {
      return update(state, {
        status: {
          $set: 'error',
        },
        error: { $set: action.payload.error },
      })
    }

    case FETCH_BLOG_CATEGORIES_SUCCESS: {
      return update(state, {
        status: { $set: 'success' },
        blog: {
          $set: action.payload.blog,
        },
        categories: {
          $set: action.payload.blog.categories
        },
      })
    }

    case UPDATE_CATEGORY_REQUEST: {
      return state
    }

    case UPDATE_CATEGORY_FAILURE: {
      return state
    }

    case UPDATE_CATEGORY_SUCCESS: {
      let index = state.categories.findIndex(
        x => x.id === action.payload.category.id,
      )

      return update(state, {
        categories: {
          [index]: { $set: action.payload.category },
        },
      })
    }

    case ARCHIVE_CATEGORY_REQUEST: {
      return state
    }

    case ARCHIVE_CATEGORY_FAILURE: {
      return state
    }

    case ARCHIVE_CATEGORY_SUCCESS: {
      let index = state.categories.findIndex(x => x.id === action.payload.category.id)

      return update(state, {
        categories: {
          $splice: [[index, 1]],
        },
      })
    }

    case DELETE_CATEGORY_REQUEST: {
      return state
    }
    
    case DELETE_CATEGORY_FAILURE: {
      return state
    }
    
    case DELETE_CATEGORY_SUCCESS: {
      let index = state.categories.findIndex(x => x.id === action.payload.id)
      
      return update(state, {
        categories: {
          $splice: [[index, 1]],
        },
      })
    }

    default:
      return state
  }
}
