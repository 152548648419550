import React from 'react'
import { CardGrid } from 'grids'
import {AssociationCard} from 'cards'
import Col from 'react-bootstrap/Col'
import { Building } from 'react-bootstrap-icons';
import { Activity } from 'react-bootstrap-icons';
import { People } from 'react-bootstrap-icons';
import { QuestionCircle } from 'react-bootstrap-icons';
import { Archive } from 'react-bootstrap-icons';
import { GridSectionHeading } from 'headings'
import { useCurrentAccountUser } from 'functions'

function Grid() {

  const accountUser = useCurrentAccountUser()

  let counters = accountUser.adminCounters || {}
  let eventsCounter = counters.events || {}
  let accountsCounter = counters.accounts || {}
  let accountUsersCounter = counters.accountUsers || {}
  let blogsCounter = counters.blogs || {}
  let knowledgeBasesCounter = counters.knowledgeBases || {}

  return (
    <CardGrid>
      <Col lg='12'>
        <GridSectionHeading
          heading='What do you want to do?'
          description='Here are some helpful shortcuts, to point you in the right direction'
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/events`}
          heading='Platform activity'
          icon={<Activity size={50} />}
          description="Review key activity on the platform"
          count={eventsCounter.active}
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/accounts`}
          heading='Accounts'
          icon={<Building size={50} />}
          description="Review and manage all the accounts on Stacked"
          count={accountsCounter.active}
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/account-users`}
          heading='Users'
          icon={<People size={50} />}
          description="Review and manage all the users on Stacked"
          count={accountUsersCounter.active}
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/blogs`}
          heading='Blogs'
          icon={<Archive size={50} />}
          description="Review and manage all the blogs on Stacked"
          count={blogsCounter.active}
        />
      </Col>

      <Col xs='6' lg='3' id='association-grid-card'>
        <AssociationCard
          link={`/knowledge-bases`}
          heading='Guides'
          icon={<QuestionCircle size={50} />}
          description="Review and manage all the guides on Stacked"
          count={knowledgeBasesCounter.active}
        />
      </Col>
    </CardGrid>
  )
}

export default Grid